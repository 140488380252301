import React from "react"
import {
    Layout,
    Button
} from "../../components"
// import blueEllipse from '../../assets/solvSure/blueEllipse.svg'
// import greyEllipse from '../../assets/solvSure/greyEllipse.svg'
import solvSureLiteII_Illustration from '../../assets/solvSure/SOLV SURE ILLUSTRATIONS-03.svg'
import solvSureIllustration from '../../assets/solvSure/SOLV SURE ILLUSTRATIONS-04.svg'
import solvSureMedicine from '../../assets/solvSure/solvSureMedicine.svg'
import QRCode from '../../assets/solvSure/QRCode.png'
import greenTick from '../../assets/solvSure/greenTick.svg'
// import seeMoreArrow from '../../assets/solvSure/seeMoreArrow.svg'
import styles from "./business-services-css-module.module.scss"
import { navigate } from "gatsby"

const SolvSure = () => {
    function handleContactUs(){
        navigate("/contact")
    }
  //  const [seeMore, setSeeMore] = useState(false);
    const details = {
        "Pharmacy Vouchers" : [
            '₹15,000 worth Apollo Pharmacy vouchers',
            '₹4,500 worth NetMed vouchers'
        ],
        "Health check-up vouchers" : [
            '84 tests for 2 members',
            '61 tests for 2 members'
        ],
        "OPD Vouchers" : [
            '10 OPD vouchers for network hospitals (4 members)',
            '-'
        ],
        "Unlimited tele-consultation with doctors" : [
            '4 Members',
            '2 Members'
        ],
        "Network discount card for discounts at 1000+ hospitals, clinics and pharmacies" : [
            '$tick$',
            '$tick$'
        ],
        "Diet-fit program voucher" : [
            '2 Members',
            '1 Member'
        ],
        "Dental wellness voucher" : [
            '2 Members',
            '-'
        ],
        "Sum insured for fire, flood and burglary for content" : [
            'Free ₹5,00,000',
            'Free ₹3,00,000'
        ],
    }

    // const solvSureLiteMobileDetails = [
    //     '₹1,500 worth Apollo Pharmacy Vouchers',
    //     'Health check-up vouchers 61 tests for 2 members',
    //     'OPD vouchers for 1 member',
    //     'Upto ₹1,00,000 Sum insured for fire, flood and burglary for content',
    //     'Network discount card for discounts at 1000+ hospitals, clinics and pharmacies'
    // ]
    
    return (
        <>
            <Layout>
            <section className={styles.landingSection}> 
                    <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
                        Protect your health <br/>and your business                          
                    </div>
                    <div className={styles.description}>
                        Solv is helping small business owners protect their most prized assets – their health and wellbeing. As a small business owner, your health is crucial for business continuity. Maintain good health with a comprehensive preventive care package brought to you by Solv.
                    </div>
                    <Button label="Contact Us &nbsp; &#8594;" handleClick={handleContactUs} />
            </section>
            <section className={styles.servicesSection}> 
                    <div className={styles.plansContainer}>
                        <div className={`${styles.plansHeading} ${styles.planItem}`}>
                            <img src={solvSureMedicine}/>
                        </div>
                        <div className={`${styles.plansHeading} ${styles.planItem}`}>
                            <img src={solvSureIllustration}/>
                            <div>
                                Solv Sure
                            </div>
                            <div className={styles.plansHeadingSub}>
                                Benefits worth ₹35,000 for 
                                <span className={`${styles.plansHeadingSubAmount} ${styles.activeAmount}`}> ₹15,000 </span> 
                                Only
                            </div>
                        </div>
                        <div className={`${styles.plansHeading} ${styles.planItem}`}>
                            <img src={solvSureLiteII_Illustration}/>
                            <div>
                                Solv Sure Lite II
                            </div>
                            <div className={styles.plansHeadingSub}>
                                Benefits worth ₹10,000 for
                                <span className={styles.plansHeadingSubAmount}> ₹5,500 </span> 
                                Only
                            </div>
                        </div>
                        { Object.keys(details).map((key,index) => {                            
                            return (
                                <>                                
                                <div key={`key${index}`} className={styles.planItem}>
                                    <div className={`${styles.plansTitle}`}>
                                        {key}
                                    </div>
                                </div>
                                <div key={`sure${index}`} className={styles.planItem}>
                                    <div className={`${styles.plansDescription}`}>
                                        {details[key][0] === '$tick$' ? <img src={greenTick} alt='available'/> : <span>{details[key][0]}</span>}
                                    </div>
                                </div>
                                <div key={`liteII${index}`} className={styles.planItem}>
                                    <div className={`${styles.plansDescription}`}>
                                        {details[key][1] === '$tick$' ? <img src={greenTick} alt='available'/> : <span>{details[key][1]}</span>}
                                    </div>
                                </div>
                                </>
                            )
                        })}
                    </div>
            </section>
            <section className={styles.footer}>
                <div className={styles.tncContainer}>
                    <Button label="Contact Us &nbsp; &#8594;" handleClick={handleContactUs} />
                    <div className={styles.tnc}>
                        *Estimated amount if all benefits are fully utilised
                    </div>
                </div>
                <div className={styles.QRSection}>                    
                    <div className={styles.QRContainer}>
                        <img src={QRCode} alt='QR Code'/>
                        <div className={styles.name}>
                            <div>VPA: <b>solvsure@sc</b></div>
                            <div>Name: <b>STANDARD CHARTERED RESEARCH AND TECHNOLOGY INDIA PRIVATE LTD</b></div>
                        </div>
                    </div>
                    <div>
                        Solv will not accept any cash payment. The payments towards the services need to be done by UPI ( solvsure@sc or QR Code), IMPS/NEFT (Standard Chartered Bank, A/C No: 45505397405 & IFSC: SCBL0036073) or by cheque in the name of “Standard Chartered Research & Technology India Pvt. Ltd.”
                    </div>
                </div>
            </section>          
            </Layout>
        </>
    )
}

export default SolvSure
